<template>
  <section class="details flexible-top-space">
    <div class="wrapper">
      <h3>Details</h3>

      <p>Spacelings are <a :href="erc721URL" target="_blank" rel="noopener">ERC-721</a> Non-Fungible Tokens (<a :href="nftURL" target="_blank" rel="noopener">NFTs</a>) on the <a :href="ethereumURL" target="_blank" rel="noopener">Ethereum</a> blockchain. The smart contract is verified and available for viewing on <a :href="etherscanURL" target="_blank" rel="noopener">Etherscan</a>. All images and metadata are stored on the InterPlanetary File System (<a :href="ipfsURL" target="_blank" rel="noopener">IPFS</a>). The collection is available for buying and selling on <a :href="openSeaURL" target="_blank" rel="noopener">OpenSea</a>.</p>
    </div>
  </section>
</template>

<script>
import CONSTANTS from '@/constants';

export default {
  name: 'TheDetails',
  data() {
    return {
      etherscanURL: CONSTANTS.ETHERSCAN_URL,
      ethereumURL: 'https://ethereum.org/en/',
      erc721URL: 'https://ethereum.org/en/developers/docs/standards/tokens/erc-721/',
      ipfsURL: 'https://ipfs.io/',
      nftURL: 'https://ethereum.org/en/nft/',
      openSeaURL: CONSTANTS.OPEN_SEA_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.details {
  padding: 32px 0;
}

.wrapper {
  max-width: 768px;
  margin: 0 auto;
  padding: 32px;
  color: color('gray', 3);
  text-align: center;
}

h2 {
  font-weight: 600;
}

p {
  font-weight: 400;
}

p a {
  color: color('blue', 3);
}
</style>
