<template>
  <div class="carousel flexible-top-space">
    <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="spaceling in spacelings" :key="spaceling">
        <div class="slide">
          <div class="box">
            <img class="image" :src="getImageUrl(spaceling)" alt="Example Spaceling">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="slide">
          <div class="box">
            <div class="view-more">
              View all on <a :href="openSeaUrl" target="_blank" rel="noopener">OpenSea</a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import CONSTANTS from '@/constants';

const SPACELINGS_TO_SHOW = 25;
const INITIAL_SPACELING = 12;
const SLIDE_TO_SPACELING = 13;

export default {
  name: 'TheCarousel',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      openSeaUrl: CONSTANTS.OPEN_SEA_URL,
      spacelings: [...Array(SPACELINGS_TO_SHOW + 1).keys()].slice(1),
      swiperOptions: {
        centeredSlides: true,
        grabCursor: true,
        initialSlide: INITIAL_SPACELING,
        slidesPerView: 3,
        spaceBetween: 0,
        breakpoints: {
          2560: { slidesPerView: 12 },
          2304: { slidesPerView: 11 },
          2048: { slidesPerView: 10 },
          1792: { slidesPerView: 9 },
          1536: { slidesPerView: 8 },
          1280: { slidesPerView: 7 },
          1024: { slidesPerView: 6 },
          768: { slidesPerView: 5 },
          512: { slidesPerView: 4 },
        },
      },
    };
  },
  methods: {
    getImageUrl(index) {
      return require(`@/assets/spacelings/${index}.png`);
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  mounted() {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.swiper.slideTo(SLIDE_TO_SPACELING, 1000, false);
      });
    });
  },
};
</script>

<style lang="scss" scoped>
$breakpoint: 1024px;

.carousel {
  position: relative;
  padding: 24px 0;

  @media (min-width: $breakpoint) {
    padding: 16px 0;
  }
}

.carousel::before,
.carousel::after {
  content: '';
  position: absolute;
  top: 0;
  width: 128px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

$background-color: color('gray', 9);

.carousel::before {
  background: linear-gradient(to right, $background-color, rgba($background-color, 0));
  left: 0;
}
.carousel::after {
  background: linear-gradient(to left, $background-color, rgba($background-color, 0));
  right: 0;
}

.swiper {
  width: 100%;
}

.slide {
  user-select: none;
  transition: transform 100ms ease;
  padding: 8px;

  @media (min-width: $breakpoint) {
    padding: 16px;
  }
}

.box {
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1;
  background-color: color('gray', 8);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $box-shadow-md;
  transition: box-shadow 100ms ease;
}

@media (hover: hover) and (pointer: fine) {
  .slide:hover {
    transform: translateY(-4px);
  }

  .slide:hover .box {
    box-shadow: $box-shadow-lg;
  }
}

.image {
  width: 100%;
  image-rendering: pixelated;
}

.view-more {
  font-family: 'Rajdhani', sans-serif;
  text-align: center;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;
  color: color('gray', 4);
  text-transform: uppercase;

  a {
    font-weight: 700;
    color: color('blue', 3);
    border-bottom: 2px solid color('blue', 3);
  }
}
</style>
