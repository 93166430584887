<template>
  <div id="app">
    <TheHeader/>
    <TheCarousel/>
    <TheIntro/>
    <TheLaunch/>
    <TheAnatomy/>
    <TheDetails/>
    <TheFooter/>
  </div>
</template>

<script>
import 'core-reset/core-reset.css';
import 'core-grid/core-grid.css';

import TheHeader from '@/components/TheHeader.vue';
import TheCarousel from '@/components/TheCarousel.vue';
import TheIntro from '@/components/TheIntro.vue';
import TheLaunch from '@/components/TheLaunch.vue';
import TheAnatomy from '@/components/TheAnatomy.vue';
import TheDetails from '@/components/TheDetails.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheCarousel,
    TheIntro,
    TheLaunch,
    TheAnatomy,
    TheDetails,
    TheFooter,
  },
}
</script>

<style lang="scss">
html {
  background-color: color('gray', 9);
  font-family: 'Titillium Web', sans-serif;
}

.flexible-top-space {
  @media (min-width: 1024px) {
    margin-top: 16px;
  }

  @media (min-width: 1280px) {
    margin-top: 32px;
  }
}

h2 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.5px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.666px;
  }
}

h3 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.416px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.5px;
  }
}

p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.375px;
  }
}

p.small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
  }
}

p.large {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.375px;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.5px;
  }
}

p.large strong {
  font-weight: 700;
  letter-spacing: 1px;
}

p a {
  text-decoration: underline;
}

h2 + p {
  margin-top: 12px;
}

h3 + p {
  margin-top: 4px;
}

p + p {
  margin-top: 24px;
}
</style>
