<template>
  <div class="launch flexible-top-space">
    <div class="wrapper">
      <div class="row">
        <div class="text-container col xs-12 sm-max">
          <h2>History</h2>

          <p>The Spacelings collection was created by <a href="https://twitter.com/K34T5" target="_blank" rel="noopener">@K34T5</a> in early 2022. All Spacelings were minted at zero cost in order to provide a fun and accessible option for NFT collectors and enthusiasts.</p>

          <p>Each Spaceling was minted with a hidden identity – a blank image with no attributes. Once all 50 NFTs were minted, the collection was updated to reveal the images and attributes.</p>

          <p>There is no utility provided to NFT holders, and there is no roadmap to add any. The only goal of the project is to serve as a fun set of collectibles to hold, trade, identify with, and rally around.</p>

          <p>Join the community on <a :href="discordURL" target="_blank" rel="noopener">Discord</a> and <a href="https://twitter.com/SpacelingsXYZ" target="_blank" rel="noopener">Twitter</a>!</p>
        </div>

        <div class="countdown-container col xs-12 sm-min">
          <div class="countdown-wrapper">
            <TheMilestones/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheMilestones from '@/components/TheMilestones.vue';
import CONSTANTS from '@/constants';

export default {
  name: 'TheLaunch',
  components: {
    TheMilestones,
  },
  data() {
    return {
      launchDateString: CONSTANTS.LAUNCH_DATE_STRING,
      openSeaUrl: CONSTANTS.OPEN_SEA_URL,
      discordURL: CONSTANTS.DISCORD_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.launch {
  padding: 32px 0;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
}

.text-container {
  padding: 16px;
  color: color('gray', 3);

  @media (min-width: 1024px) {
    margin-right: 32px;
  }
}

.countdown-container {
  padding: 16px;
}

.countdown-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

h2 {
  font-weight: 600;
}

p {
  font-weight: 400;
}

p a {
  color: color('blue', 3);
}

.reveal-images {
  max-width: 512px;
  padding: 32px 32px 32px 0;

  @media (min-width: 768px) {
    padding: 64px 64px 64px 0;
  }
}

.reveal-container {
  border-radius: 4px;
  overflow: hidden;
  background-color: color('gray', 8);
  box-shadow: $box-shadow-md;
}

.reveal-image {
  width: 100%;
  image-rendering: pixelated;
}

.reveal-arrow {
  color: color('gray', 6);
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 32px;
  padding: 0 16px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 48px;
    padding: 0 24px;
  }
}
</style>
