<template>
  <svg viewBox="0 0 2032 384">
    <path d="M292.8,209.6c0-16.72,30.03-39.64,49.78-58.64C336.42,76.19,306.61,24,184,24,80.98,24,40,80.34,40,152c0,62.28,37.66,99.25,72,136,46.18,49.42,95.67,88,128,88,47.59,0,88.94-65.57,100.68-141.34A54.34,54.34,0,0,1,328,236C300.7,236,292.8,217.72,292.8,209.6ZM184.8,236c-56.92,0-70.4-34-70.4-52.8,0-21.75,8.21-35.2,44-35.2,56.34,0,79.2,57.66,79.2,61.6C237.6,235.1,202.64,236,184.8,236Z"/>
    <path d="M446.6318,239.0293v-5.9717q0-6.2709,6.2705-6.27h25.3809q6.2711,0,6.27,6.27v2.3887q0,11.3511,4.4785,15.5273,4.48,4.1851,16.4239,4.1807H519.49q11.644,0,16.2735-4.48,4.626-4.4779,4.6279-16.7217V230.37a14.9825,14.9825,0,0,0-7.0166-13.2881q-7.02-4.626-17.4688-5.5234a188.3533,188.3533,0,0,1-22.5439-3.4346,145.3349,145.3349,0,0,1-22.5439-6.5683Q460.3624,197.5245,453.35,185.73q-7.02-11.792-7.0166-30.3076V144.9707q0-23.2909,13.1387-36.1309Q472.6069,96.0049,495.9,96h29.86q23.584,0,36.7276,12.84,13.1352,12.8424,13.1387,36.1309v5.6729q0,6.2709-6.2706,6.2714H543.9746q-6.27,0-6.27-6.2714v-1.7911q0-11.6454-4.4785-15.8261-4.4795-4.1778-16.4229-4.1807H505.1572q-12.2475,0-16.5722,4.6289-4.333,4.6334-4.33,18.0654v5.6729q0,14.0391,24.4853,16.125,25.377,2.0918,45.0889,10.1523,10.4487,4.4781,17.4678,15.9746,7.0122,11.4992,7.0176,29.4122v10.1523q0,23.2909-13.1387,36.1309-13.144,12.8423-36.43,12.84H496.1992q-23.291,0-36.43-12.84Q446.627,262.3252,446.6318,239.0293Z"/>
    <path d="M652.96,288H627.5791q-6.271,0-6.2705-6.2705V102.2705q0-6.27,6.2705-6.27h72.8584q23.2909,0,36.1309,12.69,12.8349,12.6959,12.84,35.9815v28.666q0,23.291-12.84,35.9814Q723.726,222.0137,700.4375,222.01h-37.624q-3.583,0-3.583,3.2841V281.73Q659.2305,288.0005,652.96,288Zm9.5556-99.1348H694.167q9.5523,0,13.4365-4.0312,3.8775-4.0312,3.8819-13.2881v-25.082q0-9.5538-3.8819-13.5869-3.8834-4.0312-13.4365-4.0313H662.5156q-3.29,0-3.2851,3.2852V185.58Q659.2305,188.87,662.5156,188.8652Z"/>
    <path d="M808.53,288H779.8652q-5.376,0-3.584-6.2705L823.46,102.2705q2.0859-6.27,8.958-6.27h39.7138q7.4606,0,8.958,6.27L928.27,281.73q1.7916,6.2711-3.584,6.2705h-28.665q-3.29,0-4.4795-1.3438-1.1967-1.3433-1.791-4.9267l-8.3613-32.8457H823.1621L814.8008,281.73q-.6021,3.5831-1.94,4.9267A5.9455,5.9455,0,0,1,808.53,288Zm43.2968-155.8691-21.499,86.2949h44.1934l-21.5-86.2949Z"/>
    <path d="M1042.63,288H1010.083q-23.5927,0-36.43-12.541-12.8422-12.542-12.84-36.1309V144.6719q0-23.5841,12.84-36.1309,12.8351-12.54,36.43-12.541H1042.63q23.291,0,36.28,12.69,12.9887,12.6959,12.9892,35.9815v14.0342q0,6.5712-6.5693,6.5693h-25.083q-6.27,0-6.2705-6.5693V146.4639q0-9.5538-4.0313-13.5869-4.03-4.0312-13.5859-4.0313h-20.3047q-9.2594,0-13.2881,4.0313-4.0312,4.0313-4.0312,13.5869v91.0722q0,9.5611,4.0312,13.5869,4.0313,4.0313,13.2881,4.0313h20.3047q9.5521,0,13.5859-4.0313,4.0313-4.0312,4.0313-13.5869V225.2939q0-6.5639,6.2705-6.5693h25.083q6.564,0,6.5693,6.5693v14.0342q0,23.2911-12.9892,35.9815Q1065.9214,288.0039,1042.63,288Z"/>
    <path d="M1237.3164,288h-97.6426q-6.2709,0-6.2705-6.2705V102.2705q0-6.27,6.2705-6.27h97.6426q5.9693,0,5.9717,6.27v20.3047q0,6.2711-5.9717,6.27h-62.4082q-3.583,0-3.583,3.2852v38.8183q0,3.2886,3.583,3.2842h51.0606q6.2709,0,6.2714,6.27v20.3047q0,6.271-6.2714,6.27h-51.0606q-3.583,0-3.583,3.2852v41.5048q0,3.29,3.583,3.2852h62.4082q5.9693,0,5.9717,6.27V281.73Q1243.2881,288.0005,1237.3164,288Z"/>
    <path d="M1389.8965,288h-98.2393q-6.2709,0-6.2705-6.2705V102.2705q0-6.27,6.2705-6.27h25.3809q6.2711,0,6.2705,6.27v149.3q0,3.29,3.583,3.2852h63.0049q5.9692,0,5.9717,6.5693V281.73Q1395.8682,288.0005,1389.8965,288Z"/>
    <path d="M1467.8281,102.2705V281.73q0,6.2711-6.27,6.2705h-25.3808q-6.271,0-6.2706-6.2705V102.2705q0-6.27,6.2706-6.27h25.3808Q1467.8287,96,1467.8281,102.2705Z"/>
    <path d="M1548.1514,288h-24.7842q-6.271,0-6.27-6.2705V102.2705q0-6.27,6.27-6.27h22.3945q6.271,0,8.3613,4.1807L1614.44,213.35h1.1944V102.2705q0-6.27,6.27-6.27h24.7832q6.2709,0,6.2715,6.27V281.73q0,6.2711-6.2715,6.2705h-21.7979a9.977,9.977,0,0,1-9.5547-5.9717l-59.72-111.0791h-1.1943V281.73Q1554.4219,288.0005,1548.1514,288Z"/>
    <path d="M1778.668,288h-31.6514q-23.5927,0-36.43-12.541-12.8422-12.542-12.84-36.1309V144.6719q0-23.5841,12.84-36.1309,12.8351-12.54,36.43-12.541h31.6514q23.291,0,36.1308,12.69,12.835,12.6959,12.84,35.9815v8.958q0,6.5712-6.2715,6.5693h-25.3809q-6.27,0-6.2705-6.5693v-7.166q0-9.5538-3.8818-13.5869-3.8833-4.0312-13.4365-4.0313h-19.4092q-9.2594,0-13.2881,4.0313-4.0312,4.0313-4.0313,13.5869v91.0722q0,9.5611,4.0313,13.5869,4.0313,4.0313,13.2881,4.0313h19.4092q9.5521,0,13.4365-4.0313,3.8773-4.0312,3.8818-13.5869V218.7246q0-3.2812-3.2842-3.2842h-17.916q-6.2709,0-6.27-6.27V188.8652q0-6.2709,6.27-6.2714h52.2549q6.8658,0,6.8682,6.8681v49.8662q0,23.2911-12.84,35.9815Q1801.9566,288.0039,1778.668,288Z"/>
    <path d="M1867.65,239.0293v-5.9717q0-6.2709,6.2705-6.27h25.3809q6.2709,0,6.2705,6.27v2.3887q0,11.3511,4.4785,15.5273,4.4795,4.1851,16.4238,4.1807h14.0342q11.644,0,16.2734-4.48,4.626-4.4779,4.628-16.7217V230.37a14.9825,14.9825,0,0,0-7.0166-13.2881q-7.02-4.626-17.4688-5.5234a188.3533,188.3533,0,0,1-22.5439-3.4346,145.3362,145.3362,0,0,1-22.544-6.5683q-10.4561-4.0312-17.4687-15.8262-7.02-11.792-7.0166-30.3076V144.9707q0-23.2909,13.1386-36.1309,13.1354-12.8349,36.4287-12.84h29.86q23.5841,0,36.7275,12.84,13.1353,12.8424,13.1387,36.1309v5.6729q0,6.2709-6.2705,6.2714h-25.3818q-6.27,0-6.27-6.2714v-1.7911q0-11.6454-4.4786-15.8261-4.4794-4.1778-16.4228-4.1807h-11.6455q-12.2476,0-16.5723,4.6289-4.333,4.6334-4.33,18.0654v5.6729q0,14.0391,24.4854,16.125,25.377,2.0918,45.0889,10.1523,10.4487,4.4781,17.4677,15.9746,7.0122,11.4992,7.0176,29.4122v10.1523q0,23.2909-13.1387,36.1309-13.1441,12.8423-36.43,12.84h-32.5468q-23.2911,0-36.43-12.84Q1867.6456,262.3252,1867.65,239.0293Z"/>
  </svg>
</template>

<script>
export default {
  name: 'TheLogo',
};
</script>

<style lang="scss" scoped>
path {
  fill: color('gray', 3);
}
</style>
