<template>
  <section class="anatomy flexible-top-space">
    <div class="container">
      <div class="wrapper row">
        <div class="image col xs-min">
          <img src="@/assets/anatomy.png" alt="Spaceling Anatomy">
        </div>

        <div class="text col xs-max">
          <h2>Spaceling Anatomy</h2>

          <p>Some spacelings are blue; some are orange. Some have antennae or horns on their head; some even have glowing eyes. The combination of physical traits is what makes each spaceling unique.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheAnatomy',
};
</script>

<style lang="scss" scoped>
.anatomy {
  padding: 32px 0;
}

.container {
  background-color: color('teal', 0);
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-end;
  }
}

.text {
  padding: 32px 32px 32px 0px;

  @media (min-width: 512px) {
    padding: 32px 32px 32px 16px;
  }

  @media (min-width: 768px) {
    padding: 64px 32px 64px 32px;
  }
}

img {
  width: 128px;

  @media (min-width: 768px) {
    width: 256px;
  }
}

h2 {
  color: color('teal', 9);
}

p {
  color: color('teal', 9);
}
</style>
