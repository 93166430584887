<template>
  <a
    v-if="href"
    :href="href"
    class="button normal primary"
    target="_blank"
    rel="noopener"
  >
    {{ name }}
  </a>

  <button
    v-else
    :class="['button', 'normal', 'primary', { 'loading': loading }]"
    v-text="name"
    @click="$emit('click')"
    :disabled="disabled || loading"
  />
</template>

<script>
export default {
  name: 'BaseButton',
  props: ['name', 'href', 'loading', 'disabled'],
};
</script>

<style lang="scss" scoped>
.button {
  font-family: 'Rajdhani', sans-serif;
  display: inline-block;
  border-radius: 4px;
  box-shadow: $box-shadow-md;
  transition: text-shadow 50ms ease;
  text-transform: uppercase;
  font-weight: 700;
  cursor: default;
}

.button:not(:disabled) {
  cursor: pointer;
}

.button.normal {
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.6px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
  }
}

.button.normal:active {
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: none;
}

$hue: 'green';

.button.primary {
  color: color($hue, 1);
  background-color: color($hue, 7);
  border-top: 2px solid color($hue, 6);
  border-bottom: 2px solid color($hue, 8);
}

.button.primary:active {
  border-top: 2px solid color($hue, 8);
  border-bottom: 2px solid color($hue, 6);
}

.button:disabled:not(:loading) {
  color: color('gray', 5);
  background-color: color('gray', 7);
  border-top: 2px solid color('gray', 6);
  border-bottom: 2px solid color('gray', 8);
}

@media (hover: hover) and (pointer: fine) {
  .button.primary:not(:disabled):hover {
    text-shadow: color($hue, 0) 0px 0px 8px;;
  }
}

.button.loading {
  position: relative;
  color: transparent;
}

$spinner-size: 24px;

.button.loading::after {
  content: '';
  position: absolute;
  top: calc(50% - ($spinner-size / 2));
  left: calc(50% - ($spinner-size / 2));
  width: $spinner-size;
  height: $spinner-size;
  border-radius: 50%;
  border: 2px solid color($hue, 1);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
