<template>
  <section class="intro flexible-top-space">
    <div class="wrapper">
      <p class="large"><strong>Spacelings are unique collectible characters from outer space.</strong> The collection consists of only 50 Spacelings, each with their own distinct set of physical traits.</p>

      <div class="interactive">
        <BaseButton
          :href="openSeaUrl"
          name="View Collection"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CONSTANTS from '@/constants';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'TheIntro',
  components: {
    BaseButton,
  },
  data() {
    return {
      openSeaUrl: CONSTANTS.OPEN_SEA_URL,
      nowAvailable: false,
    };
  },
  computed: {
    whenToClaim() {
      return this.nowAvailable ? 'now' : `on ${CONSTANTS.LAUNCH_DATE_STRING}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  justify-content: center;
}

.wrapper {
  margin: 0 auto;
  max-width: 768px;
  padding: 32px;
  text-align: center;
}

.interactive {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}

p {
  color: color('gray', 3);
}
</style>
