<template>
  <ol class="milestones">
    <li
      v-for="milestone in milestones"
      :key="milestone.name"
      :class="['milestone', { 'revealed': milestone.achieved }]"
    >
      <div class="checkbox"></div>
      <div class="text">
        <p class="value">{{ milestone.name }}</p>

        <p class="reveal-status small">
          {{ milestone.date }}
        </p>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'TheMilestones',
  data() {
    return {
      milestones: [
        {
          name: 'Spacelings announced',
          date: 'Febrary 12, 2022',
          achieved: true,
        },
        {
          name: 'Minting begins',
          date: 'March 5, 2022',
          achieved: true,
        },
        {
          name: 'Minting completes',
          date: 'March 9, 2022',
          achieved: true,
        },
        {
          name: 'Spacelings revealed',
          date: 'March 10, 2022',
          achieved: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.milestones {
  padding: 8px 0;
}

.milestone {
  display: flex;
  position: relative;

  & + .milestone::before {
    content: '';
    position: absolute;
    top: calc(-100% + 4px);
    left: calc(16px - 2px);
    width: 4px;
    height: 100%;
    background-color: color('gray', 7);
  }

  &.revealed + .milestone.revealed::before {
    background-color: color('green', 6);
  }

  & + .milestone {
    margin-top: 24px;
  }

  .checkbox {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: color('gray', 7);
    color: color('gray', 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    z-index: 1;
  }

  &.revealed .checkbox {
    background-color: color('green', 6);
  }

  &.revealed .checkbox::after {
    content: '\2713';
  }

  .text {
    margin-left: 16px;
  }

  .value {
    color: color('gray', 5);
    font-weight: 700;
  }

  &.revealed .value {
    color: color('gray', 3);
  }

  .reveal-status {
    margin-top: 0;
    color: color('gray', 5);
  }
}
</style>
