<template>
  <footer class="footer flexible-top-space">
    <p class="small">A project by <a href="https://twitter.com/K34T5" target="_blank" rel="noopener">@K34T5</a></p>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  padding: 32px;
}

p {
  color: color('gray', 4);
  font-weight: 400;
}

p a {
  color: color('blue', 3);
}
</style>
