<template>
  <header class="header flexible-top-space">
    <a href="/" aria-label="Spacelings">
      <TheLogo class="logo"/>
    </a>
  </header>
</template>

<script>
import TheLogo from '@/components/TheLogo.vue';
export default {
  name: 'TheHeader',
  components: {
    TheLogo,
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 32px;
  text-align: center;
}

.logo {
  max-width: 192px;

  @media (min-width: 512px) {
    max-width: 256px;
  }

  @media (min-width: 768px) {
    max-width: 320px;
  }

  @media (min-width: 1024px) {
    max-width: 384px;
  }
}
</style>
