export default {
  LAUNCH_DATE_UTC: [2022, 2, 11, 1],
  LAUNCH_DATE_STRING: 'Thu, Mar. 10th at 8pm EST',

  MAX_SUPPLY: 50,
  MAX_MINT_AMOUNT: 25,
  COST_DISPLAY: 0,
  COST_WEI: 0,
  GAS_LIMIT_WEI: 285000,

  /**
   * Update for launch
   */

  CONTRACT_ADDRESS: '0xC65C072E6D6829A4317bc65C225dBE0ADfCBa1E6',
  OWNER_ADDRESS: '0xB8Eb4E1B774cDACF268548848A23fcaab0630faC',
  NET_VERSION: '1', // Rinkeby: '4', Mainnet: '1'
  ETHERSCAN_URL: 'https://etherscan.io/address/0xC65C072E6D6829A4317bc65C225dBE0ADfCBa1E6',
  OPEN_SEA_URL: 'https://opensea.io/collection/spacelings-collection',
  DISCORD_URL: 'https://discord.gg/VFbraXcQra',
  // TODO: UPDATE ABI
};
